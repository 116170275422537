import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.css'
import bootsratp from 'bootstrap/dist/js/bootstrap.bundle.js';
import '@/assets/scss/style.scss';
import '@/assets/libs/@iconscout/unicons/css/line.css';
import '@/assets/libs/@mdi/font/css/materialdesignicons.min.css';
import MasonryWall from '@yeger/vue-masonry-wall'
import router from './router'
import { createI18n } from 'vue-i18n'
import { createPinia } from 'pinia'

const i18n = createI18n({
    locale: 'et',
    fallbackLocale: 'en',
    messages: {
        et: {
            message: {
                string: 'tere, eheee'
            }
        }
    }
})

const app = createApp(App)

app.use(MasonryWall)
app.use(createPinia())
app.use(i18n)
app.use(bootsratp)
app.use(router)
app.mount('#app')